<template>
  <v-row v-if="bill" class="rowDialog">
    <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430" class="dialog">
      <v-card class="contentCard">
        <v-card-title class="headline pb-0">
          <v-col cols="10" class="modal-title">
            Gerar Novo Boleto
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon  @click="closeModal" class="close-icon">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

        <v-divider  class="mx-6 ml-6 divider divider-consult"></v-divider>
        <v-col v-if="getLoading" cols="12" align="center">
          <v-progress-circular
          :size="100"
          color="#D70472"
          indeterminate
          ></v-progress-circular>
          <h3 class="textInfoDisable mt-6">Enviando e-mail...</h3>
        </v-col>
        <v-card-text v-show="!getLoading" class="modal-form">

          <v-row justify="center" class="success-text">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-2 mt-4" src="@/assets/success.svg" />
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row justify="center">
                <v-col cols="10" md="7" class="pt-0">
                  <p class="text-success mb-0">Novo boleto gerado com sucesso!</p>
                </v-col>
                <v-col cols="10" md="8" class="pt-0 pb-0">
                  <p class="text-value" id="text_element">{{service.billetsFormat(bill.barCode)}}</p>
                </v-col>
                <v-col cols="10" md="8" class="pt-0 pb-0">
                  <p class="text-title">Valor</p>
                  <p class="text-value">{{value}}</p>
                </v-col>
                <v-col cols="10" md="8" class="pt-0">
                  <p class="text-title">Data de vencimento</p>
                  <p class="text-value">{{date}}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row justify="center">
                <v-btn
                  v-if="!coppied"
                  rounded
                  outlined
                  color="#8C8C8C"
                  @click.stop.prevent="copyCode"
                  class="btnBack">
                  Copiar código de barras
                </v-btn>
                <span v-else>
                  {{ msg }}
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-row justify="center">
                <v-btn
                  v-if="!emailSuccess"
                  rounded
                  color="#00C1C1"
                  @click="sendEmailHandle"
                  class="btnClose">
                  Enviar por e-mail
                </v-btn>
                <span v-else>
                  E-mail enviado!
                </span>
              </v-row>
              <input class="barcode-input" type="hidden" id="value-barcode" :value="bill.barCode">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import service from '../../../service';

export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      service,
      coppied: false,
      msg: '',
    };
  },
  computed: {
    ...mapGetters({
      bill: 'DigitalAccount/getBill',
      emailSuccess: 'DigitalAccount/getSendEmail',
      getLoading: 'Loading/getLoading'
    }),
    date() {
      return this.$moment(this.bill.dueDate).format('DD/MM/YYYY');
    },
    value() {
      return this.service.formatCurrency(this.bill.value);
    },
  },
  methods: {
    ...mapActions({
      sendEmail: 'DigitalAccount/sendEmail',
      hasRole: 'User/hasRole',
    }),
    sendEmailHandle() {
      this.sendEmail({
        id: this.bill.transactionId,
      });
    },
    copyCode() {
      const codeToCopy = document.querySelector('#value-barcode');
      codeToCopy.setAttribute('type', 'text');
      codeToCopy.select();

      try {
        document.execCommand('copy');
        this.msg = 'Código de barras copiado!';
      } catch {
        this.msg = 'Erro ao copiar o código de barras';
      }
      codeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
      this.coppied = true;
    },
    async closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/modalDeposits.scss';
</style>
