<template>
  <v-container>
    <v-col mb="12" cols="12" class="contentDeposits">
      <v-row class="newPapper">
        Gerar um novo boleto de depósito
      </v-row>
      <v-col md="8" class="titleDeposits pl-md-0">
        Pague onde preferir e receba o dinheiro na sua conta em até 3 dias úteis após o pagamento.
      </v-col>
        <v-form
          ref="form"
          @submit.prevent="submit"
        >
        <v-row>
          <v-col md="3">
            <v-text-field
              v-model="value"
              id="form"
              label="Informe o valor que deseja depositar"
              v-money="money"
              maxlength="16"
              :rules="validateValue"
              validate-on-blur
              required
            ></v-text-field>
          </v-col>
          <v-col md="9"></v-col>
          <v-col align="left" md="6" class=" pt-6">
            <v-btn class="button" width="220" color="#D70472" rounded dark @click="submit">
              Gerar Boleto
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <ModalDeposits :dialog="dialog" @closeModal="closeModal"/>
       <v-snackbar
        v-model="error"
        :timeout="timeout"
        color="red"
        top
      >
      {{ error }}
       <v-btn
          color="white"
          text
          @click="clearError"
        >
          Fechar
        </v-btn>
    </v-snackbar>
    </v-col>
  </v-container>
</template>
<script>
import { VMoney } from 'v-money';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalDeposits from './ModalDeposits.vue';
import service from '../../../service';

export default {
  components: {
    ModalDeposits,
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      dialog: false,
      value: '',
      service,
      timeout: 5000,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
      },
      validateValue: [
        valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) >= 20.00) || 'Digite um valor entre R$20,00 e R$99.999.999,99',
        valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) < 100000000) || 'Digite um valor entre R$20,00 e R$99.999.999,99',
      ],

    };
  },
  methods: {
    ...mapActions({
      depositByBill: 'DigitalAccount/depositByBill',
    }),
    ...mapMutations({
      clearError: 'DigitalAccount/CLEAR_ERROR',
      clearGlobalError: 'Error/CLEAR_ERROR',
    }),
    async submit() {
      if (this.$refs.form.validate()) {
        await this.depositByBill(this.service.cleanCurrency(this.value));
        this.value = '';
        this.dialog = true;
      }
    },
    closeModal() {
      this.dialog = false;
      document.getElementById('form').value = '';
      this.value = '';
    },
  },
  computed: {
    ...mapGetters({
      error: 'DigitalAccount/getError',
    }),
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/deposits.scss';
</style>
